const migrations = {
  2: (state: any) => {
    return {
      ...state,
      leadQualification: {
        ...state.leadQualification,
        goalsRanking: [
          'final_price',
          'efficiency',
          'warranty',
          'wifi',
          'sound_level',
        ],
      },
    };
  },
  3: (state: any) => {
    return {
      ...state,
      leadQualification: {
        ...state.leadQualification,
        last_page_visited: 'contact-info',
      },
    };
  },
  4: (state: any) => {
    return {
      ...state,
      leadQualification: {
        ...state.leadQualification,
        customizations: [],
      },
    };
  },
  5: (state: any) => {
    return {
      ...state,
      leadQualification: {
        ...state.leadQualification,
        hasCustomizations: false,
      },
    };
  },
  6: (state: any) => {
    return {
      ...state,
      leadQualification: {
        ...state.leadQualification,
        householdIncome: '',
        householdMembers: '',
      },
    };
  },
  7: (state: any) => {
    return {
      ...state,
      leadQualification: {
        ...state.leadQualification,
        basement_height: '',
      },
    };
  },
  8: (state: any) => {
    const warrantyIndex =
      state.leadQualification.goalsRanking.findIndex('warranty');

    return {
      ...state,
      leadQualification: {
        ...state.leadQualification,
        goalsRanking: [
          ...state.leadQualification.goalsRanking.slice(0, warrantyIndex),
          ...state.leadQualification.goalsRanking.slice(warrantyIndex + 1),
        ],
      },
    };
  },
  9: (state: any) => {
    return {
      ...state,
      leadQualification: {
        ...state.leadQualification,
        homeDetails: {
          ...state.leadQualification.homeDetails,
          bath_count: '',
          bath_count_total: '',
          bath_partial_count: '',
        },
      },
    };
  },
  10: (state: any) => {
    let addressState = '';
    if (state.address.contains('MA')) {
      addressState = 'MA';
    } else if (state.address.contains('NY')) {
      addressState = 'NY';
    }

    return {
      ...state,
      rebateEligibility: {
        ...state.rebateEligibility,
        state: addressState,
      },
    };
  },
  11: (state: any) => {
    const addressState = state.rebateEligibility.state;

    return {
      ...state,
      leadQualification: {
        ...state.leadQualification,
        addressData: {
          city: '',
          state: '',
          street_address: '',
          zip_code: '',
        },
        homeDetails: {
          ...state.leadQualification.homeDetails,
          bath_count: undefined,
          bath_partial_count: undefined,
          bedrooms_count: undefined,
          city: undefined,
          condo_association: undefined,
          dms_btus: undefined,
          fully_finished_basement: undefined,
          home_style: undefined,
          municipal_utilities: undefined,
          reference_id: undefined,
          siding_type: undefined,
          state: undefined,
          stories_count: undefined,
          street_address: undefined,
          units_count: undefined,
          zip_code: undefined,
        },
      },
      rebateEligibility: {
        ...state.rebateEligibility,
        addressData: {
          city: '',
          state: addressState,
          street_address: '',
          zip_code: '',
        },
        state: undefined,
      },
    };
  },
  12: (state: any) => {
    return {
      ...state,
      rebateEligibility: {
        ...state.rebateEligibility,
        homeDetails: {
          ...state.rebateEligibility.homeDetails,
          area_in_sqft: '',
          bath_count: '',
          bath_count_total: '',
          bath_partial_count: 0,
          central_ac: '',
          heating_system: '',
          zones: '',
        },
      },
    };
  },
  13: (state: any) => {
    return {
      ...state,
      leadQualification: {
        ...state.leadQualification,
        homeDetails: {
          ...state.leadQualification.homeDetails,
          basement_height: state.leadQualification.basement_height || '',
          fully_finished_basement:
            state.leadQualification.basement_layout || '',
        },
      },
    };
  },
  14: (state: any) => {
    return {
      ...state,
      rebateEligibility: {
        ...state.rebateEligibility,
        homeDetails: {
          ...state.rebateEligibility.homeDetails,
          basement_height: state.rebateEligibility.basement_height || '',
          fully_finished_basement:
            state.rebateEligibility.basement_layout || '',
        },
      },
    };
  },
  15: (state: any) => {
    return {
      ...state,
      rebateEligibility: {
        ...state.rebateEligibility,
        utilityProvider: '',
      },
    };
  },
  16: (state: any) => {
    return {
      ...state,
      leadQualification: {
        ...state.leadQualification,
        home_occupancy:
          state.leadQualification.home_occupancy === 'not_sure'
            ? 'not-sure'
            : state.leadQualification.home_occupancy,
      },
      rebateEligibility: {
        ...state.rebateEligibility,
        homeOccupancy: '',
        summerHome: undefined,
      },
    };
  },
  17: (state: any) => {
    return {
      ...state,
      onboarding: {
        ...state.onboarding,
        cameFromRebateEligibility: false,
      },
    };
  },
  18: (state: any) => {
    return {
      ...state,
      onboarding: {
        ...state.onboarding,
        acRanking: '',
        carbonFootprintRanking: '',
        costVsEfficiencyRanking: '',
        isSystemBroken: '',
      },
    };
  },
  19: (state: any) => {
    return {
      ...state,
      onboarding: {
        ...state.onboarding,
        systemPreference: {
          heat_pumps: false,
          heat_pumps_with_backup_system: false,
          same_kind_of_system_I_have: false,
        },
      },
    };
  },
  20: (state: any) => {
    return {
      ...state,
      rebateEligibility: {
        ...state.rebateEligibility,
        barrierRemediation: '',
        insulationRequirement: '',
        whatIsAHea: '',
      },
    };
  },
  21: (state: any) => {
    return {
      ...state,
      onboarding: {
        ...state.onboarding,
        homeDataId: '',
        homeDetails: {
          area_in_sqft: '',
          basement_height: '',
          bath_count: '',
          bath_count_total: '',
          bath_partial_count: 0,
          central_ac: '',
          fuel_type: '',
          fully_finished_basement: '',
          heating_system: '',
          home_style: '',
          home_type: '',
          id: '',
          municipal_utilities: false,
          units_count: 0,
          year_built: '',
          zones: '',
        },
      },
      rebateEligibility: {
        ...state.rebateEligibility,
        homeDetails: {
          area_in_sqft: '',
          basement_height: '',
          bath_count: '',
          bath_count_total: '',
          bath_partial_count: 0,
          central_ac: '',
          fuel_type: '',
          fully_finished_basement: '',
          heating_system: '',
          home_style: '',
          home_type: '',
          id: '',
          municipal_utilities: false,
          units_count: 0,
          year_built: '',
          zones: '',
        },
      },
    };
  },
  22: (state: any) => {
    return {
      ...state,
      onboarding: {
        ...state.onboarding,
        numberOfFloors: 0,
      },
    };
  },
  23: (state: any) => {
    return {
      ...state,
      onboarding: {
        ...state.onboarding,
        firstFloorBathrooms: 0,
      },
    };
  },
  24: (state: any) => {
    return {
      ...state,
      onboarding: {
        ...state.onboarding,
        firstFloorBedrooms: 0,
        firstFloorRooms: {
          kitchen_living: false,
          bedrooms: false,
          bathrooms: false,
          other: false,
        },
      },
    };
  },
  25: (state: any) => {
    return {
      ...state,
      onboarding: {
        ...state.onboarding,
        commonAreaLayout: '',
      },
    };
  },
  26: (state: any) => {
    return {
      ...state,
      leadQualification: {
        ...state.leadQualification,
        homeDetails: {
          ...state.leadQualification.homeDetails,
          cooling_system:
            state.leadQualification.central_ac === 'Yes'
              ? 'Central AC'
              : state.leadQualification.central_ac === 'No'
              ? 'None'
              : 'Window AC',
        },
      },
      onboarding: {
        ...state.onboarding,
        homeDetails: {
          ...state.onboarding.homeDetails,
          cooling_system:
            state.onboarding.central_ac === 'Yes'
              ? 'Central AC'
              : state.onboarding.central_ac === 'No'
              ? 'None'
              : 'Window AC',
        },
      },
      rebateEligibility: {
        ...state.rebateEligibility,
        homeDetails: {
          ...state.rebateEligibility.homeDetails,
          cooling_system:
            state.rebateEligibility.central_ac === 'Yes'
              ? 'Central AC'
              : state.rebateEligibility.central_ac === 'No'
              ? 'None'
              : 'Window AC',
        },
      },
    };
  },
  27: (state: any) => {
    return {
      ...state,
      onboarding: {
        ...state.onboarding,
        firstFloorOther: 0,
      },
    };
  },
  28: (state: any) => {
    return {
      ...state,
      onboarding: {
        ...state.onboarding,
        secondFloorRooms: {
          bedrooms: false,
          bathrooms: false,
          other: false,
        },
        secondFloorBathrooms: 0,
        secondFloorBedrooms: 0,
        secondFloorOther: 0,
      },
    };
  },
  29: (state: any) => {
    return {
      ...state,
      onboarding: {
        ...state.onboarding,
        secondFloorRooms: {
          ...state.onboarding.secondFloorRooms,
          kitchen_living: false,
        },
        thirdFloorRooms: {
          kitchen_living: false,
          bedrooms: false,
          bathrooms: false,
          other: false,
        },
      },
    };
  },
  30: (state: any) => {
    return {
      ...state,
      onboarding: {
        ...state.onboarding,
        thirdFloorBathrooms: 0,
        thirdFloorBedrooms: 0,
        thirdFloorOther: 0,
      },
    };
  },
  31: (state: any) => {
    return {
      ...state,
      onboarding: {
        ...state.onboarding,
        fourthFloorRooms: {
          kitchen_living: false,
          bedrooms: false,
          bathrooms: false,
          other: false,
        },
        fourthFloorBathrooms: 0,
        fourthFloorBedrooms: 0,
        fourthFloorOther: 0,
      },
    };
  },
  32: (state: any) => {
    return {
      ...state,
      onboarding: {
        ...state.onboarding,
        basementLayout: '',
        basementRooms: 0,
      },
    };
  },
  33: (state: any) => {
    return {
      ...state,
      onboarding: {
        ...state.onboarding,
        fourthFloorRooms: {
          kitchen_living: false,
          bedrooms: false,
          bathrooms: false,
          other: false,
        },
        fourthFloorBathrooms: 0,
        fourthFloorBedrooms: 0,
        fourthFloorOther: 0,
        otherRooms: {
          three_season_room: false,
          mudroom: false,
          garage: false,
          other: false,
        },
        ceilingCassetteRooms: 0,
      },
    };
  },
  34: (state: any) => {
    return {
      ...state,
      onboarding: {
        ...state.onboarding,
        fourthFloorRooms: {
          kitchen_living: false,
          bedrooms: false,
          bathrooms: false,
          other: false,
        },
        fourthFloorBathrooms: 0,
        fourthFloorBedrooms: 0,
        fourthFloorOther: 0,
      },
    };
  },
  35: (state: any) => {
    return {
      ...state,
      onboarding: {
        ...state.onboarding,
        ceilingCassetteRooms: '',
      },
    };
  },
  36: (state: any) => {
    return {
      ...state,
      onboarding: {
        ...state.onboarding,
        commonAreaFloor: 0,
      },
    };
  },
  37: (state: any) => {
    return {
      ...state,
      onboarding: {
        ...state.onboarding,
        homeDetails: {
          ...state.onboarding.homeDetails,
          basementRooms: 0,
          commonAreaLayout: '',
          firstFloorBathrooms: 0,
          firstFloorBedrooms: 0,
          firstFloorOther: 0,
          fourthFloorBathrooms: 0,
          fourthFloorBedrooms: 0,
          fourthFloorOther: 0,
          numberOfFloors: 0,
          secondFloorBathrooms: 0,
          secondFloorBedrooms: 0,
          secondFloorOther: 0,
          thirdFloorBathrooms: 0,
          thirdFloorBedrooms: 0,
          thirdFloorOther: 0,
        },
      },
    };
  },
  38: (state: any) => {
    return {
      ...state,
      leadQualification: undefined,
    };
  },
  39: (state: any) => {
    return {
      ...state,
      onboarding: {
        ...state.onboarding,
        selectedSystemType: '',
        selectedSystemManufacturer: '',
      },
    };
  },
  40: (state: any) => {
    return {
      ...state,
      onboarding: {
        ...state.onboarding,
        selectedSystem: {
          photo: '',
          badge: '',
          our_pick: false,
          final_price: '',
          fuel: 'None',
          highlighted_features: [],
          loan: {
            duration: 7,
            yearly_rate: '',
            name: 'None',
          },
          manufacturer: '',
          model_family: '',
          monthly_payment: '',
          provides: 'None',
          system_name: 'None',
          system_rebate: '',
          system_type: 'None',
          tax_incentive: 0,
          upfront_price: '',
        },
      },
    };
  },
  41: (state: any) => {
    return {
      ...state,
      onboarding: {
        ...state.onboarding,
        suggestedSystems: [],
        paymentType: 'loan',
      },
    };
  },
  42: (state: any) => {
    return {
      ...state,
      onboarding: {
        ...state.onboarding,
        selectedSystem: {
          ...state.onboarding.selectedSystem,
          whats_included: [],
        },
      },
    };
  },
  43: (state: any) => {
    return {
      ...state,
      onboarding: {
        ...state.onboarding,
        sqft: '',
      },
    };
  },
  44: (state: any) => {
    return {
      ...state,
      onboarding: {
        ...state.onboarding,
        waterHeaterUpgrade: '',
      },
    };
  },
  45: (state: any) => {
    return {
      ...state,
      onboarding: {
        ...state.onboarding,
        waterHeaterAge: '',
      },
    };
  },
  46: (state: any) => {
    return {
      ...state,
      onboarding: {
        ...state.onboarding,
        homeDetails: {
          ...state.onboarding.homeDetails,
          waterHeaterAge: '',
          acAge: '',
        },
      },
    };
  },
  47: (state: any) => {
    return {
      ...state,
      onboarding: {
        ...state.onboarding,
        preferenceRanking: [
          'lower-utility-bills',
          'get-off-fossil-fuels',
          'lowest-upfront-price',
          'roi',
          'add-central-ac',
        ],
      },
    };
  },
  48: (state: any) => {
    return {
      ...state,
      onboarding: {
        ...state.onboarding,
        recommendOrSelect: '',
      },
    };
  },
  49: (state: any) => {
    return {
      ...state,
      onboarding: {
        ...state.onboarding,
        acRanking: undefined,
        carbonFootprintRanking: undefined,
        costVsEfficiencyRanking: undefined,
      },
    };
  },
  50: (state: any) => {
    return {
      ...state,
      onboarding: {
        ...state.onboarding,
        unevenlyHeatedRooms: undefined,
      },
    };
  },
  51: (state: any) => {
    return {
      ...state,
      onboarding: {
        ...state.onboarding,
        projectGoals: '',
      },
    };
  },
  52: (state: any) => {
    return {
      ...state,
      onboarding: {
        ...state.onboarding,
        preferenceRanking: [
          'lower-utility-bills',
          'get-off-fossil-fuels',
          'lowest-upfront-price',
          'roi',
        ],
      },
    };
  },
  53: (state: any) => {
    return {
      ...state,
      walkthroughs: {
        ...state.walkthroughs,
      },
    };
  },
};

export default migrations;
